// http://ionicframework.com/docs/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
*, *:before, *:after {
	box-sizing: border-box;
}

a {
	cursor: pointer;
}

.enable-hover a:hover {
	opacity: 1;
}

.sm-hide {
	display: none;

	@include media-breakpoint-up( 'md' ) {
		display: inherit;
	}
}

ion-grid[no-padding] {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

@include media-breakpoint-up( 'sm' ) {
	ion-grid[no-padding] {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

ion-menu .list .item {
	color: $dark;
	font-size: 18px;
	padding-left: 0;

	&:last-child {
		color: $green;
		font-weight: 900;
	}

	.item-inner {
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 1rem;
	}
}