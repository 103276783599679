// ion-grid[no-padding] {
// 	padding: 0;
// 	> div { padding: 0; }
// }
.clearfix::before, .clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ion-content[preventscrolling="true"] .scroll-content {
	overflow: hidden;
}

.text-green {
	color: $green;
}

ion-list[flex] {
  display: flex;
  flex-wrap: wrap;
}

.range-bar {
	border-radius: 4px;
	box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.14);
	top: 17px !important;
}

.range-knob {
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
	top: 5px !important;
}

[range-left] {
	margin: 0 21px 0 0 !important;
}

[range-right] {
	margin: 0 0 0 21px !important;
}

.swiper-button-prev, .swiper-button-next {
	background-size: 67% !important;
}

.swiper-pagination-bullet-active {
	background: $blue !important;
}

.toast-wrapper {
  top: 20px !important;
  border-radius: 5px;
  background: rgba(0,0,0,0.85) !important;
  border: 1px solid #000;
  .toast-message {
  	font-size: 18px !important;
  	font-weight: 900 !important;
  	line-height: 22px !important;
  }
}