@keyframes coinsBgSm {
  0% {
    background-position: top left;
  }
  100%{
    background-position: top right -410px;
  }
}

@keyframes socialBgSm {
  0% {
    background-position: top right -190px;
  }
  100%{
    background-position: top left;
  }
}

@keyframes coinsBgMd {
  0% {
    background-position: top left;
  }
  100%{
    background-position: top right -547px;
  }
}

@keyframes socialBgMd {
  0% {
    background-position: top right -254px;
  }
  100%{
    background-position: top left;
  }
}

@keyframes coinsBgLg {
  0% {
    background-position: top left;
  }
  100%{
    background-position: top right -481px;
  }
}

@keyframes socialBgLg {
  0% {
    background-position: top right -702px;
  }
  100%{
    background-position: top left;
  }
}

@keyframes coinsBgXl {
  0% {
    background-position: top left;
  }
  100%{
    background-position: top right -417px;
  }
}

@keyframes socialBgXl {
  0% {
    background-position: top right -638px;
  }
  100%{
    background-position: top left;
  }
}

page-home {

	a { text-decoration: none; }

	.button {
		text-shadow: 0px 2px 4px rgba(0,0,0,0.18);
	}

	.button-group {
		max-width: 300px;
		margin: 0 auto;

		@include media-breakpoint-up( 'md' ) {
			max-width: 370px;
		}

		@include media-breakpoint-up( 'lg' ) {
			max-width: 422px;
		}

		.button {
			width: 50%;
			background: $blue;
			color: #fff;
			text-align: center;
			font-size: 14px;
			font-weight: 900;
			border: 1px solid $blue-dark;
			padding: 10px 0;

			@include media-breakpoint-up( 'md' ) {
				font-size: 16px;
				padding: 12px 0;
			}

			@include media-breakpoint-up( 'lg' ) {
				font-size: 18px;
				padding: 15px 0;
				margin-bottom: 20px;
			}

			&.active {
				background: $blue-dark;
				box-shadow: inset 0 1px 5px 0 rgba(0,0,0,0.50);
			}

			&:first-child {
				float: left;
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
				border-right: none;
			}
			&:last-child {
				float: right;
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
	}

	.header-ctn {
		position: relative;
		z-index: 4;
		border-bottom: 5px solid $green;
		box-shadow: 0px 2px 4px rgba(0,0,0,0.5);

		&__header {
			width: 100%;
			padding: 10px 0;
			float: left;

			@include media-breakpoint-up( 'md' ) {
				padding: 15px 0;
			}

			@include media-breakpoint-up( 'lg' ) {
				padding: 20px 0;
			}

			&__logo {
				float: left;
				width: 61px;

				@include media-breakpoint-up( 'md' ) {
					width: 80px;
				}

				@include media-breakpoint-up( 'lg' ) {
					width: 95px;
				}
			}

			&__links-ctn {
				float: right;
		    position: relative;
		    top: 3px;

		    @include media-breakpoint-up( 'md' ) {
		    	top: 6px;
		    }

		    &__mobile-menu-open {
		    	display: block;
		    	font-size: 32px;
		    	position: relative;
		    	bottom: 3px;
		    	cursor: pointer;

		    	small {
		    		font-size: 14px;
		    		color: $blue;
		    		vertical-align: middle;
		    	}

		    	ion-icon {
		    		vertical-align: middle;
		    		margin-left: 7px;
		    		color: $blue;
		    	}

		    	@include media-breakpoint-up( 'md' ) {
						display: none;
					}
		    }

				ul {
					display: none;
					list-style: none;
					margin: 0;

					@include media-breakpoint-up( 'md' ) {
						display: block;
					}

					li {
						display: inline-block;
						vertical-align: middle;

						&:not(:last-child) { margin-right: 30px; }

						a {
							font-size: 18px;
							font-weight: bold;
							color: $dark;
							line-height: 44px;
							cursor: pointer;

							@include media-breakpoint-up( 'md' ) {
								font-size: 16px;
							}

							@include media-breakpoint-up( 'lg' ) {
								font-size: 18px;
							}

							&:hover { color: $blue; }

							&.button {
								background: $green;
								border: 1px solid $green-dark;
								border-radius: 4px;
								color: #fff;
								width: 155px;
								font-weight: bolder;
								transition: transform 180ms ease-in-out, background 180ms ease-in-out !important;
								transform: scale(1);
								box-shadow: 0 1px 4px rgba(0,0,0,0.3);
								&:hover {
									transform: scale(1.08);
									background: #95D142;
								}

								@include media-breakpoint-up( 'lg' ) {
									width: 175px;
								}
							}
						}
					}
				}
			}
		}
	}

	.hero-ctn {
		position: relative;
		z-index: 4;
		background: url('../assets/imgs/home-bg-hero-blur.png') no-repeat center center;
		background-size: cover;
		border-bottom: 7px solid $blue;

		&__hero-copy {
			position: relative;
			padding-top: 15px;
			padding-bottom: 90px;
			max-width: 623px;
			color: #fff;
			text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.75);
			text-align: center;
			margin: 0 auto;
			z-index: 5;

			@include media-breakpoint-up( 'sm' ) {
				margin-bottom: -30px;
				padding-bottom: 0;
			}

			@include media-breakpoint-up( 'lg' ) {
				max-width: 800px;
			}

			h1 {
				font-size: 24px;
				line-height: 42px;
				font-weight: 900;
				margin-bottom: 10px;

				@include media-breakpoint-up( 'sm' ) {
					font-size: 32px;
				}

				@include media-breakpoint-up( 'md' ) {
					font-size: 40px;
					line-height: 54px;
					margin-bottom: 15px;
				}

				@include media-breakpoint-up( 'lg' ) {
					font-size: 48px;
					line-height: 62px;
					margin-bottom: 30px;
				}
			
				@include media-breakpoint-up( 'xl' ) {
					font-size: 56px;
					margin-bottom: 50px;
				}
			}

			h2 {
				font-size: 18px;
				line-height: 25px;
				margin-bottom: 20px;

				@include media-breakpoint-up( 'md' ) {
					font-size: 24px;
					line-height: 31px;
					margin-bottom: 30px;
				}

				@include media-breakpoint-up( 'lg' ) {
					font-size: 30px;
					line-height: 36px;
					margin-bottom: 40px;
				}

				@include media-breakpoint-up( 'xl' ) {
					font-size: 36px;
					line-height: 42px;
				}
			}

			.button {
				background: $green;
				border: 1px solid $green-dark;
				border-radius: 6px;
				color: #fff;
				width: 155px;
				font-size: 16px;
				line-height: 47px;
				font-weight: 900;
				box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
				transition: transform 180ms ease-in-out, background 180ms ease-in-out !important;
				transform: scale(1);

				&:hover {
					transform: scale(1.08);
					background: #95D142;
				}

				// &.learn-more {
				// 	@include media-breakpoint-down( 'sm' ) {
				// 		display: none;
				// 	}
				// }

				// &.sign-up {
				// 	@include media-breakpoint-up( 'md' ) {
				// 		display: none;
				// 	}
				// }

				@include media-breakpoint-up( 'md' ) {
					font-size: 18px;
					line-height: 48px;
					width: 176px;
				}

				@include media-breakpoint-up( 'lg' ) {
					font-size: 24px;
					line-height: 61px;
					width: 230px;
				}

				@include media-breakpoint-up( 'xl' ) {
					margin-bottom: -40px;
				}
			}
		}

		&__phones {
			position: relative;
			z-index: 1;
			display: none;

			&::before {
		    content: '';
		    position: absolute;
		    height: 50%;
		    top: 10px;
		    left: 0;
		    width: 100%;
		    background: url(../assets/imgs/home-bg-tipcoins.png) repeat-x top center;
		    padding: 0 121px;
		    background-clip: content-box;
		    background-size: contain;
		    transform: scale(0.7);
		    @include media-breakpoint-up( 'sm' ) {
			    animation: coinsBgSm 14s linear infinite;
			  }
		    @include media-breakpoint-up( 'md' ) {
			    animation: coinsBgMd 14s linear infinite;
			    padding: 0 163px;
			  }
				@include media-breakpoint-up( 'lg' ) {
			    animation: coinsBgLg 13s linear infinite;
			    padding: 0 140px;
			  }
				@include media-breakpoint-up( 'xl' ) {
			    animation: coinsBgXl 10s linear infinite;
			  }
			}

			&::after {
				content: '';
				position: absolute;
				height: 50%;
				top: 50%;
				left: 0;
				width: 100%;
				background: url('../assets/imgs/home-bg-social-icons.png') repeat-x top center;
				padding: 0 121px;
		    background-clip: content-box;
		    background-size: contain;
		    transform: scale(0.7);
		    @include media-breakpoint-up( 'sm' ) {
			    animation: socialBgSm 8s linear infinite;
			  }
		    @include media-breakpoint-up( 'md' ) {
			    animation: socialBgMd 8s linear infinite;
					padding: 0 163px;
			  }
		    @include media-breakpoint-up( 'lg' ) {
			    animation: socialBgLg 15.5s linear infinite;
					padding: 0 140px;
			  }
		    @include media-breakpoint-up( 'xl' ) {
			    animation: socialBgXl 12.5s linear infinite;
			  }
			}

			@include media-breakpoint-up( 'sm' ) {
				display: inherit;
			}

			ion-col {
				position: relative;
				z-index: 100;
				&:last-child { text-align: right; }
			}

			img {
				margin-bottom: -3px;
			}

			&__logo {
				position: absolute;
				bottom: -33px;
				width: 103px;

				@include media-breakpoint-up( 'sm' ) {
					width: 70px;
					bottom: -16px;
				}

				@include media-breakpoint-up( 'md' ) {
					bottom: -33px;
					width: 103px;
				}

				@include media-breakpoint-up( 'lg' ) {
					bottom: -23px;
				}

				&--left {
					left: 73px;

					@include media-breakpoint-up( 'sm' ) {
						left: 55px;
					}

					@include media-breakpoint-up( 'md' ) {
						left: 73px;
					}

					@include media-breakpoint-up( 'lg' ) {
						left: 86px;
					}
				}
				&--right {
					right: 70px;
					
					@include media-breakpoint-up( 'sm' ) {
						right: 55px;
					}

					@include media-breakpoint-up( 'md' ) {
						right: 70px;
					}

					@include media-breakpoint-up( 'lg' ) {
						right: 84px;
					}
				}
			}
		}
	}

	.hero-ctn, .section-ctn {
		.section__icon {
			width: 134px;
			position: absolute;
			bottom: -88px;
			left: 50%;
			margin-left: -67px;
			z-index: 3;

			@include media-breakpoint-up( 'md' ) {
				width: 152px;
		    bottom: -96px;
		    margin-left: -76px;
			}

			@include media-breakpoint-up( 'lg' ) {
				width: 174px;
				bottom: -108px;
				margin-left: -87px;
			}
		}
	}

	.section-ctn {
		position: relative;
		z-index: 2;
		padding-top: 90px;
		color: #232323;

		@include media-breakpoint-up( 'md' ) {
			padding-top: 105px;
		}

		@include media-breakpoint-up( 'lg' ) {
			padding-top: 120px;
		}

		.gradient-ctn {
			position: relative;
			z-index: 2;
			background-image: radial-gradient(closest-side at 50% 131%, #FFFFFF 46%, rgba(255,255,255,0.00) 100%);
			padding-bottom: 60px;
		}

		h3 {
			font-weight: 900;
			font-size: 24px;
			
			@include media-breakpoint-up( 'md' ) {
				font-size: 29px;
			}

			@include media-breakpoint-up( 'lg' ) {
				font-size: 36px;
				margin-top: 40px;
			}
		}

		h4 {
			font-weight: 900;
			font-size: 16px;

			@include media-breakpoint-up( 'lg' ) {
				font-size: 21px;
			}
		}

		p {
			font-size: 16px;
			line-height: 24px;
			max-width: 791px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;

			@include media-breakpoint-up( 'md' ) {
				font-size: 18px;
				line-height: 26px;
				max-width: 590px;
			}

			@include media-breakpoint-up( 'lg' ) {
				font-size: 21px;
				line-height: 29px;
				max-width: 730px;
			}

			a:not(.button) {
				text-decoration: underline;
				color: $dark;
			}

			&.small {
				position: relative;
				font-size: 14px;
				line-height: 18px;
				max-width: 299px;
				margin: 0 auto 10px;

				&::after {
					content: '';
	        width: 86%;
			    left: 7%;
			    height: 1px;
			    background: $blue;
			    display: block;
			    position: relative;
			    top: 12px;
				}

				&::before {
					content: '';
					position: absolute;
					bottom: -21px;
					left: 50%;
					margin-left: -10px;
					width: 0;
				  height: 0;
				  border-left: 10px solid transparent;
				  border-right: 10px solid transparent;
				  border-top: 10px solid $blue;
				}

				@include media-breakpoint-up( 'md' ) {
					font-size: 13px;
					line-height: 17px;
				}

				@include media-breakpoint-up( 'lg' ) {
					font-size: 16px;
					line-height: 1.8;
					margin-bottom: 20px;
				}
			}

			&.small-margin { margin-bottom: 10px; }
		}

		&__section1 {
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#daf2fd+0,ffffff+15,ffffff+92,eeeeee+100 */
			background: #daf2fd; /* Old browsers */
			background: -moz-linear-gradient(top, #daf2fd 0%, #ffffff 11%, #ffffff 92%, #eeeeee 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #daf2fd 0%,#ffffff 11%,#ffffff 92%,#eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #daf2fd 0%,#ffffff 11%,#ffffff 92%,#eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daf2fd', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
			text-align: center;
			border-bottom: 7px solid $yellow;
			z-index: 3;
			padding-top: 0;

			.button {
				transition: background 180ms ease-in-out;
			}

			.button:not(.active):hover {
				background: #49C8FF;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url('../assets/imgs/home-bg-lines.png') center;
				background-size: cover;
			}

			.section-ctn--main-copy p:last-of-type {
				margin-top: 25px;
				margin-bottom: 10px;
			}

			&--phones {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			.slides .swiper-slide img {
				max-width: 100%;
				max-height: 430px;

				@include media-breakpoint-up( 'sm' ) {
					max-width: 320px;
				}

				@include media-breakpoint-up( 'md' ) {
					max-width: 100%;
					max-height: 690px;
				}
			}

			.gradient-ctn {
				padding-top: 90px;
				background-size: cover;
			}

			@include media-breakpoint-up( 'md' ) {
				.swiper-button-prev, .swiper-button-next {
					display: none;
				}
			}
		}
		
		&__section2 {
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fdfad9+0,ffffff+15,ffffff+92,e4e4e4+100 */
			background: #fdfad9; /* Old browsers */
			background: -moz-linear-gradient(top, #fdfad9 0%, #ffffff 11%, #ffffff 95%, #e4e4e4 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #fdfad9 0%,#ffffff 11%,#ffffff 95%,#e4e4e4 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #fdfad9 0%,#ffffff 11%,#ffffff 95%,#e4e4e4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdfad9', endColorstr='#e4e4e4',GradientType=0 ); /* IE6-9 */
			padding-bottom: 80px;
			z-index: 2;
			border-bottom: 7px solid $purple;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url("../assets/imgs/layer-1.png") repeat-x top left 0px,url("../assets/imgs/layer-2.png") repeat-x top left 110px,url("../assets/imgs/layer-3.png") repeat-x top left 530px;
				background-size: auto 110px;
			}

			@include media-breakpoint-up( 'sm' ) {
				padding-bottom: 120px;
			}

			.section-ctn--main-copy {
				text-align: center;

				h4 {
					margin-bottom: 30px;
				}

				p {
					margin-top: 8px;
					margin-bottom: 30px;
					max-width: 310px;

					@include media-breakpoint-up('sm') {
						font-size: 14px;
						line-height: 22px;
					}

					@include media-breakpoint-up('md') {
						font-size: 16px;
						line-height: 24px;
						max-width: none;
					}

					@include media-breakpoint-up('lg') {
						font-size: 18px;
						line-height: 26px;
					}
				}

				img {
					max-width: 80px;

					@include media-breakpoint-up('md') {
						max-width: none;
					}
				}

				.button {
					background: $yellow;
					border: 1px solid $yellow-dark;
					width: 240px;
					font-weight: 900;
					color: #fff;
					box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
					padding: 15px 0;
					font-size: 18px;
					border-radius: 6px;
					transition: transform 180ms ease-in-out, background 180ms ease-in-out !important;
					transform: scale(1);

					&:hover {
						transform: scale(1.08);
						background: #F2E641;
					}

					@include media-breakpoint-up( 'md' ) {
						padding: 15px 0;
						font-size: 18px;
						width: 240px;
					}

					@include media-breakpoint-up( 'lg' ) {
						padding: 17px 0;
						font-size: 24px;
						width: 286px;
					}
				}
			}
		}

		&__section3 {
			position: relative;
			z-index: 1;
			text-align: center;
			color: #fff;
			background: url('../assets/imgs/home-bg-signup.png') no-repeat center center;
			background-size: cover;
			border-bottom: 7px solid $red;
			
			h3, p { text-shadow: 0 2px 4px rgba(0,0,0,0.5); }
			p a:not(.button) { color: #fff; }

			&__form-ctn {
				margin: 30px 0;
				
				p {
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 10px;
				}

				.unsubscribe {
					max-width: 280px;
			    font-size: 10px;
			    width: 100%;
			    color: #fff;
			    margin: 0 auto;

			    @include media-breakpoint-up( 'sm' ) {
						max-width: 430px;
					}

			    a {
			    	cursor: pointer;
			    }
				}

				&__form {
					position: relative;
					max-width: 280px;
					margin: 0 auto;
					box-shadow: 0 2px 4px rgba(0,0,0,0.14);
					border-radius: 6px;

					@include media-breakpoint-up( 'sm' ) {
						max-width: 430px;
					}

					input, .button {
						float: left;
					}

					input {
						font-size: 16px;
						font-weight: lighter;
						padding: 8px 0 9px 15px;
						border-radius: 0px;
						border-top-left-radius: 6px;
						border-bottom-left-radius: 6px;
						border: 1px solid #DFDFDF;
						border-right: none;
						color: #595959;
						width: 179px;
						height: 46px;

						&.ng-valid {
						  border-left: 5px solid $green;
						}

						&.ng-invalid:not(.ng-pristine)  {
						  border-left: 5px solid $red;
						}

						@include media-breakpoint-up( 'sm' ) {
							font-size: 24px;
							width: 300px;
						}
					}

					.button {
						background: $purple;
						border: 1px solid $purple-dark;
						color: #fff;
						font-size: 18px;
						font-weight: bold;
						padding: 13px 0 14px;
						border-top-right-radius: 6px;
						border-bottom-right-radius: 6px;
						width: 100px;
						transition: background 180ms ease-in-out !important;

						&:hover {
							background: $green-light;
						}

						&.unsub {
							background: $red;
							border: 1px solid $red-dark;
							&:hover { background: $red-light; }
						}

						ion-spinner { margin: -7px 0; }

						ion-spinner * {
							stroke: #fff;
							fill: #fff;
						}

						@include media-breakpoint-up( 'sm' ) {
							width: 130px;
						}
					}

					form.success {
						input { border-left: none; }
						.button {
							background: $green;
							border: 1px solid $green-dark;
							&.unsub {
								background: $red;
								border: 1px solid $red-dark;
							}
						}
					}
				}
			}

			&__social {
				ul {
					list-style: none;
					padding: 0;
					margin: 0 auto 90px;
					max-width: 320px;

					@include media-breakpoint-up( 'sm' ) {
						max-width: none;
					}

					li {
						display: inline-block;
						width: 66px;
						&:not(:last-child) { margin-right: 18px; }
						&:nth-child(-n+3) {
							position: relative;
							left: 8px;
						}

						@include media-breakpoint-up( 'sm' ) {
							&:nth-child(-n+3) {
								left: 0;
							}
						}

						a {
							transform: scale(1);
							display: block;
							transition: transform 180ms ease-in-out;
							&:hover { transform: scale(1.2); }
						}
					}
				}
			}
		}

	}

	.footer {
		padding: 15px 0;
		text-align: center;

		@include media-breakpoint-up( 'sm' ) {
			text-align: left;
		}

		p {
			font-size: 12px;
			margin: 0;

			@include media-breakpoint-up( 'md' ) {
				font-size: 14px;
			}

			@include media-breakpoint-up( 'lg' ) {
				font-size: 18px;
			}
		}

		&__left {
			margin-bottom: 10px;

			@include media-breakpoint-up( 'sm' ) {
				margin-bottom: 0;
				float: left;
			}

			p {
				font-weight: bold;
				color: #989898;
			}
		}

		&__right {
			@include media-breakpoint-up( 'sm' ) {
				float: right;
			}

			p a:not(.button) {
				color: #000;
				
				&:hover { text-decoration: underline; }

				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}
	}
}

.form-popover .popover-content {
  transform: scale(1) translate(85px,-43px) !important;
  text-align: center;
}
