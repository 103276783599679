form {
	margin-top: 10px;

	input, select, option, textarea { outline: none !important; }
}

.successtext {
	text-align: center;
	margin-bottom: 25px;
	padding-top: 10px;
}