/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 21, 2018 */

$lato-font-path: $font-path !default;

@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-black-webfont.eot');
    src: url('#{$lato-font-path}/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$lato-font-path}/lato-black-webfont.woff2') format('woff2'),
         url('#{$lato-font-path}/lato-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}




@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-bold-webfont.eot');
    src: url('#{$lato-font-path}/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$lato-font-path}/lato-bold-webfont.woff2') format('woff2'),
         url('#{$lato-font-path}/lato-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-italic-webfont.eot');
    src: url('#{$lato-font-path}/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$lato-font-path}/lato-italic-webfont.woff2') format('woff2'),
         url('#{$lato-font-path}/lato-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-light-webfont.eot');
    src: url('#{$lato-font-path}/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$lato-font-path}/lato-light-webfont.woff2') format('woff2'),
         url('#{$lato-font-path}/lato-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-regular-webfont.eot');
    src: url('#{$lato-font-path}/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$lato-font-path}/lato-regular-webfont.woff2') format('woff2'),
         url('#{$lato-font-path}/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}