// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/



// Font path is used to include ionicons,
// roboto, and noto sans fonts
$font-path: "../assets/fonts";



// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;



@import "ionic.globals";



// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/theming/overriding-ionic-variables/
$app-direction: multi;
$font-family-base: 'Lato', sans-serif;
$grid-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1024px
);
$grid-padding-widths: (
  xs: 10px,
  sm: 10px,
  md: 10px,
  lg: 15px,
  xl: 15px
);



// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

$colors: (
  primary:    #46BFF3,
  secondary:  #8CC63E,
  danger:     #D04B4B,
  warning:    #EBE03E,
  light:      #F4F4F4,
  dark:       #242424,
  white:      #FFFFFF
);

$dark: #242424;
$light: #F4F4F4;

$blue: #46BFF3;
$blue-dark: #1A95C9;
$green-light: #7954BF;
$green: #8CC63E;
$green-dark: #659F18;
$yellow: #EBE03E;
$yellow-dark: #C2B613;
$red-light: #e43333;
$red: #D04B4B;
$red-dark: #8A1919;
$purple: #6545A0;
$purple-dark: #3F2078;
$orange: #FFB14C;
// $orange-dark: ;



// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here
$button-ios-opacity-hover: 1;
$button-ios-clear-opacity-hover: 1;
$segment-button-ios-opacity-hover: 1;
$range-ios-knob-width: 32px;
$range-ios-bar-height: 8px;
$range-ios-bar-background-color: #CBCBCB;



// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here
$button-md-background-color-hover: $green;
$font-family-md-base: 'Lato', sans-serif;
$range-md-knob-width: 32px;
$range-md-bar-height: 8px;
$range-md-bar-background-color: #CBCBCB;



// App Windows Variables
// --------------------------------------------------
// Windows only Sass variables can go here
$font-family-wp-base: 'Lato', sans-serif;
$range-wp-knob-width: 32px;
$range-wp-bar-height: 8px;
$range-wp-bar-background-color: #CBCBCB;



// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.
@import "ionic.theme.default";
@import "customizations";



// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/ionicons/
@import "ionic.ionicons";



// Fonts
// --------------------------------------------------
@import "lato";
