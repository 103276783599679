.form-ctn {
	margin: 0 auto;
	text-align: center;
	@media (min-height: 768px) {
    height: 100%;
  }

	ion-grid {
		@media (min-height: 768px) {
	    height: 100%;
	  }

		ion-row {
			position: relative;
		}
	}

	&__intro {
		flex: 2;
		p {
			margin: 0 auto 20px;
			max-width: 470px;
		}
	}

	&__fine-print {
		flex: 1;
		margin-top: 13px;
		p {
			margin: 5px 0;
			text-align: left;
			padding-left: 7px;
			max-width: 300px;
			&:not(:last-child) {
				border-bottom: 1px solid $light;
				padding-bottom: 5px;
			}

			span {
				display: inline-block;
				width: 144px;
			}
		}
	}
	
	&__controls {
		flex: 3;
		border-top: 1px solid $light;
		border-bottom: 1px solid $light;

		@include media-breakpoint-up( 'sm' ) {
			&::before {
				content: '';
				position: absolute;
				width: 3px;
				background: $light;
				left: 50%;
				margin-left: -1.5px;
				height: 100%;
			}
		}

		@include media-breakpoint-up( 'sm' ) {
			ion-col:first-child {
				padding-right: 15px;
			}
			ion-col:last-child {
				padding-left: 15px;
			}
		}

		p {
			font-weight: bold;
			color: #595959;
			font-size: 16px;
			line-height: 19px;
			margin-bottom: 22px;

			select { margin-left: 3px; }
		}
	}

	ion-range {
		padding-left: 0;
		padding-right: 0;
	}

	input {
		width: 154px;
		text-align: center;
		font-size: 36px;
		line-height: 1;
		border: 1px solid #DFDFDF;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
		border-radius: 6px;
		font-weight: lighter;
		padding-top: 7px;
		padding-bottom: 7px;
		color: #595959;
		margin-bottom: 4px;
	}

	.range-knob {
		transform: scale(1);
	}

	// &__total {
	// 	text-align: right;

	// 	p {
	// 		margin-bottom: 0;

	// 		&:first-child {
	// 			font-size: 14px;
	// 			font-weight: normal;
	// 			border-bottom: 1px solid #DFDFDF;
	// 			padding-bottom: 3px;
	// 		}

	// 		&:last-child {
	// 			font-size: 20px;
	// 			margin-top: 8px;
	// 		}
	// 	}
	// }
}